import '/assets/styles/pages/listing.scss';
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Functions
import isExact from '@wearetla/tla-essentials-tools/functions/is-exact';

// Partials
import { Input } from '/views/partials/forms'
import Loader from '/views/partials/loader'
import Pagination from '/views/partials/pagination'
import Eventbox from '/views/partials/eventbox';
import EmptyMessage from '/views/partials/empty-message'
// import Link from '@wearetla/tla-essentials-tools/partials/link';

// Context
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator'
import { useLocations } from '/utilities/locations';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { ListingProvider, useListing, decodeQueryString } from '@wearetla/tla-essentials-tools/utilities/listing';
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';

// Hooks
import useBranches from '/hooks/branches';

// Data
import listingFilters from '/data/listing-filters/events';
// import sortingOptions from '/data/sorting-options';

// Services
import siteServices from '/services/site';
import eventServices from '/services/event';

const defaultQuery = {
	page: '1',
	order: 'asc',
	limit: '12',
	branch: '',
}

const allLocationsKey = 'tumu';

const getEvents = (query) => {
	return eventServices.getEvents(query);
}

const parseHead = (branch) => {
	return {
		title: branch?.city ? `${branch.title} Etkinlikleri` : 'Tüm Etkinlikler',
		description: branch?.city ? `Tüm ${branch.title} etkinlikleri.` : 'Tüm IF Performance Hall etkinlikleri',
	}
}

const EventsProvider = ({match, ...props}) => {
	const { locations } = useLocations();
	const city = useMemo(() => {
		const foundLocation = locations.find(l => (l.value || 'tumu') === match.params.location);

		return foundLocation?.value || '';
	}, [locations, match])
	
	const externalParams = useMemo(() => ({
		location: city
	}), [city])

	return (
		<ListingProvider
			externalParams={externalParams}
			availableFilters={listingFilters}
			queryString={match?.params?.query}
			defaultQuery={defaultQuery}>
			<Events {...props} />
		</ListingProvider>
	)
}

EventsProvider.propTypes = {
	match: PropTypes.object,
}

const Events = ({ pageProps }) => {
	const branches = useBranches();
	const { locationOptions } = useLocations();
	const { setNotFound } = useGlobalState();
	const { query, inputQuery, outputData, setFilter, paginate } = useListing();
	const { redirect } = useNavigator();
	const { setHead } = useHead();

	const [{events, pagination, fetchedQuery}, setData] = useState(pageProps?.eventData ?? {events: null, fetchedQuery: null, pagination: null});
	
	// const selectedSortOption = useMemo(() => {
	// 	return sortingOptions.find(o => o.value === inputQuery.order)
	// }, [inputQuery])

	const branchOptions = useMemo(() => ([
		{
			label: 'Tümü',
			value: false,
		},
		...(branches ?? []).map(branch => ({
			label: branch.title,
			value: branch.slug,
		}))
	]), [branches])

	const selectedBranch = useMemo(() => branchOptions.find(f => f.value === (query.branch || false)) ?? null, [branchOptions, query])

	const selectedLocation = useMemo(() => {
		return locationOptions.find(l => {
			return (l.value || '') === inputQuery.location;
		});
	}, [inputQuery, locationOptions])

	useEffect(() => {
		setHead(parseHead(selectedLocation));
	}, [selectedLocation])

	useEffect(() => {
		if(!isExact(fetchedQuery, inputQuery)) {
			setData({ events: null, pagination: null, fetchedQuery: inputQuery });
			getEvents(inputQuery).then(eventData => {
				setData({ ...eventData, fetchedQuery: inputQuery});
			}).catch(() => {
				setNotFound();
			})
		}
	}, [inputQuery, fetchedQuery])

	useEffect(() => {
		const params = {
			location: selectedLocation?.value || allLocationsKey,
		};

		// Şehir değişmemişse query yokla, url değiştir.
		if((selectedLocation?.value || '') === (outputData?.externalParams?.location ?? '')) {
			if(outputData.queryString?.length) {
				params.query = outputData.queryString;
			}
			redirect('eventsWithLocation', params)
		}

	}, [outputData, selectedLocation])

	useEffect(() => {
		if(!selectedLocation && inputQuery?.location !== '') {
			setNotFound();
		}
	}, [selectedLocation, inputQuery])

	return (
		<div className="listing-wrap wrapper">
			<h1 className="listing-title">Etkinlikler</h1>

			<nav className="listing-filters">
				<div className="filters-left">
					{/* <div className="filters-locations cities">
						<strong className="locations-title">Nerede?</strong>
						{locationOptions.map((location) => (
							<Link
								href={location?.slug ? 'eventsWithLocation' : 'events'}
								params={location?.slug ? { location: location.slug } : undefined}
								key={location?.id ?? 'empty'}
								className={classNames('locations-item', { selected: (location.city ?? '') === inputQuery?.location })}>
								<span className="item-check"></span>
								<span>{location.shortTitle}</span>
							</Link>
						))}
					</div> */}

					<div className="filters-city">
						<Input
							label="Şehir"
							z={2}
							type="select"
							onChange={city => {
								redirect(city?.value ? 'eventsWithLocation' : 'events', (
									city?.value ? { location: city.value } : {}
								))
							}}
							value={selectedLocation}
							options={locationOptions} />
					</div>
					<div className="filters-branch">
						<Input
							label="Şube"
							z={1}
							type="select"
							onChange={(c) => {
								setFilter('branch', c.value);
							}}
							value={selectedBranch}
							options={branchOptions} />
					</div>
				</div>

				{/* <Input
					className="filters-sorting"
					type="select"
					value={selectedSortOption}
					onChange={(option) => {
						setFilter('order', option.value);
					}}
					label="Sıralama"
					options={sortingOptions} /> */}
			</nav>

			<div className="listing-results loader-container">
				<Loader loading={events === null} />
				{events && events.length > 0 &&
					<>
						<ul className="results-events">
							{events.map((event) => (
								<li className="listing-event" key={event.calendar_id}>
									<Eventbox
										analytics={{
											listId: `EVENTS_${(selectedLocation?.value || 'ALL').toUpperCase()}`,
											listName: `Etkinlikler - ${selectedLocation?.shortTitle}`
										}}
										event={event} />
								</li>
							))}
						</ul>
						<Pagination
							className="results-pagination"
							data={pagination}
							onPaginate={paginate} />
					</>
				}
				{events?.length === 0 &&
					<EmptyMessage>Etkinlik Bulunamadı.</EmptyMessage>
				}
			</div>
		</div>
	)
}

Events.propTypes = {
	pageProps: PropTypes.object,
}

EventsProvider.getServerProps = ({ match, sharedData }) => {
	return new Promise((resolve) => {
		const selectedLocation = (sharedData?.locations ?? []).find(l => (l.value ?? 'tumu') === match.params.location);

		const query = decodeQueryString({
			availableFilters: listingFilters,
			queryString: match.params.query,
			defaultQuery,
			externalParams: { location: selectedLocation?.slug ?? '' }
		});

		Promise.all([
			getEvents(query),
			siteServices.getAllBranches(),
		]).then(([
			eventsPayload,
			branches,
		]) => {
			const eventData = {events: eventsPayload.events, fetchedQuery: query, pagination: eventsPayload.pagination};

			resolve({
				pageProps: {
					eventData,
				},
				sharedData: {
					branches,
				}
			})
		}).catch(() => {
			resolve({
			})
		})
	});
}

export default EventsProvider